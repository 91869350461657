import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useMetaMaskAuth from '../hooks/useMetamaskAuth';

const MetaMaskPopup = () => {
  const [searchParams] = useSearchParams();
  const userId = Number(searchParams.get('userId'));
  const navigate = useNavigate();
  const { walletAddress, error } = useMetaMaskAuth(userId);

  useEffect(() => {
    if (walletAddress) {
      navigate('/success');
    } else if (error) {
      navigate('/error');
    }
  }, [walletAddress, error, navigate]);

  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/metamask.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="text-center">
        <h3 className="text-2xl">Connecting...</h3>
      </div>
    </div>
  );
};

export default MetaMaskPopup;
