import { useState, useEffect } from "react"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Progress } from "@/components/ui/progress"
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { PlusCircle, Link as LinkIcon } from "lucide-react"
import { User } from '../types/user';
import { ArrowUpRight } from "lucide-react"
import { FaLinkedin } from 'react-icons/fa';

interface StudentProps {
  user: User | null;
}

interface codecryptocommitPost {
    url: string;
    timestamp: number;
    author: {
        name: string;
        image: {
            url: string;
            '@type': 'ImageObject';
        };
        url: string;
        '@type': 'Person';
    };
    datePublished: string;
    content: string;
    shared: boolean;
};

export default function Linkedin({ user }: StudentProps) {
  const [newLink, setNewLink] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [codecryptocommitPosts, setCodecryptocommitPosts] = useState(user?.customFields.linkedin?.codecryptocommit || []);
  const [loading, setLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const MAX_LINKS = 8

  const linkedinUser = user?.customFields.linkedin;
  // const progress = (codecryptocommitPosts.length / MAX_LINKS) * 100;

  const fetchUpdatedUserData = async () => {
    if (!user?.id) return;
    try {
      const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/profile/${user?.id}`);
      if (!response.ok) {
            throw new Error('Failed to fetch user data');
      }    

      const updatedUser = await response.json();
      setCodecryptocommitPosts(updatedUser.customFields.linkedin.codecryptocommit || []);
    } catch (error) {
      console.error("Error fetching updated user data:", error);
    }
  };

  useEffect(() => {
      const updateUserDataIfNeeded = async () => {
          if (submissionSuccess) {
              await fetchUpdatedUserData();
              setSubmissionSuccess(false);
          }
      };

      updateUserDataIfNeeded();
  }, [submissionSuccess]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setErrorMessage(null);
    if (newLink && codecryptocommitPosts.length < MAX_LINKS) {
        setLoading(true);

        try {
            const response = await fetch(
                `${import.meta.env.VITE_SERVER_URL}/api/linkedin/codecryptocommit/${user?.id}?linkedinPost=${newLink}`,
                {
                  method: "GET",
                  headers: {
                  "Content-Type": "application/json",
                  },
                }
            );

            const res = await response.json();
            if (response.ok) {
                setNewLink("");
                setIsDialogOpen(false);
                setSubmissionSuccess(true);
            } else {
                setErrorMessage(res.error || "Unknown error occurred.");
            }

        } catch (error) {
            console.error("Error submitting codecryptocommit post", error);
            setErrorMessage("Failed to submit LinkedIn post. Please try again.");
        } finally {
            setLoading(false);
        }
    }
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp)
    return date.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })
  }

  if (!linkedinUser) {
    return (
       <Card className="flex items-center justify-center h-full p-14 bg-gradient-to-r from-gray-100 to-gray-200">
        <div className="text-center space-y-4">
          <FaLinkedin className="w-16 h-16 mx-auto text-gray-400 transition duration-300 hover:text-gray-600" /> {/* GitHub logo */}
          <p className="text-sm text-gray-500 transition duration-300 hover:text-gray-700">Connect your Github account</p>
        </div>
      </Card>      
    );
  }

  return (
    <Card className="w-full max-w-4xl">
      <CardHeader className="pb-3">
        <CardTitle>LinkedIn</CardTitle>
        <p className="text-sm text-muted-foreground">
          {linkedinUser.name}
        </p>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
        <div className="flex items-center space-x-2"> 
          <h3 className="text-sm font-medium text-muted-foreground">#codecryptocommit</h3>
          <a
           href="https://codecrypto.academy/wp-content/uploads/2024/09/Plantilla-CodeCryptocommit.pdf"
           target="_blank"
           rel="noopener noreferrer"
           className="flex items-center text-xs font-medium text-teal-500 hover:underline">
            view template
            <ArrowUpRight className="h-4 w-4" />
           </a>
        </div>
          <Progress value={(codecryptocommitPosts.length / MAX_LINKS) * 100} className="w-full [&>*]:bg-teal-500">
            <div className="h-full transition-all" style={{ width: `${(codecryptocommitPosts.length / MAX_LINKS) * 100}%` }} />
          </Progress>
          <div className="text-right text-sm text-muted-foreground">
            {codecryptocommitPosts.length} / {MAX_LINKS}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="default" className="w-full bg-teal-500">
                <PlusCircle className="mr-2 h-4 w-4" />
                Add LinkedIn Post
              </Button>
            </DialogTrigger>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <DialogHeader>
                  <DialogTitle>Add LinkedIn Post Link</DialogTitle>
                  <DialogDescription>
                    Enter the URL of your LinkedIn post below.
                  </DialogDescription>
                </DialogHeader>
                <div className="py-4">
                  <Input
                    value={newLink}
                    onChange={(e) => setNewLink(e.target.value)}
                    placeholder="https://www.linkedin.com/post/..."
                  />
                </div>
                {errorMessage && (
                  <p className="text-red-500 text-sm">{errorMessage}</p> // Display error message
                )}
                <DialogFooter>
                  <Button type="submit" disabled={loading || codecryptocommitPosts.length >= MAX_LINKS}>
                    {loading ? "Submitting..." : codecryptocommitPosts.length >= MAX_LINKS ? "Max Links Reached" : "Submit"}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="secondary" className="w-full font-semibold">
                View Links {/*({codecryptocommitPosts.length})*/}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80">
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                {codecryptocommitPosts.map((post: codecryptocommitPost, index: number) => (
                  <div key={index} className="flex items-center space-x-2 mb-2">
                    <LinkIcon className="h-4 w-4" />
                    <div className="flex flex-col">
                      <a 
                        href={post.url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-sm hover:underline text-teal-500"
                      >
                        {post.url}
                      </a>
                      <span className="text-xs text-muted-foreground">
                        {formatDate(post.timestamp)}
                      </span>
                    </div>
                  </div>
                ))}
              </ScrollArea>
            </PopoverContent>
          </Popover>
        </div>
      </CardContent>
    </Card>
  )
}
