const Success = () => {
  return (
    <div
      className="flex justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/success.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    ></div>
  );
};

export default Success;
