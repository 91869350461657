import { useState, useEffect } from 'react';

const useMetaMaskAuth = (userId: number) => {
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleMetaMaskAuth = async () => {
      if (window.ethereum?.isMetaMask) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const walletAddress = accounts[0];
          const challengeMsg = `Accept registering your public address ${walletAddress} with codecrypto.academy`;

          const confirmation = await window.ethereum.request({
            method: 'personal_sign',
            params: [challengeMsg, walletAddress],
          });
          console.log(confirmation)

          if (confirmation) {
            const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/auth/metamask/login/${userId}/${walletAddress}`, {
              method: 'GET',
            });

            if (response.ok) {
                setWalletAddress(walletAddress);
              } else {
                setError('Failed to verify signature.');
              }
          }
        } catch (error) {
          console.error('Error linking MetaMask:', error);
          setError('Failed to link MetaMask.');
        }
      } else {
        setError('MetaMask is not installed.');
      }
    };

    handleMetaMaskAuth();
  }, [userId]);

  return { walletAddress, error };
};

export default useMetaMaskAuth;
