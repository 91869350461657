import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import {
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import ShineBorder from '@/components/magicui/shine-border';
import { FaGithub, FaDiscord, FaLinkedin, FaEthereum } from 'react-icons/fa';
import { User } from '../types/user';
import toast from 'react-hot-toast';
import { Tilt } from 'react-tilt';

import Github from '../dashboard/Github';
import Linkedin from '../dashboard/Linkedin';

interface ProfileProps {
  user: User;
  showDashboard: boolean;
  setShowDashboard: React.Dispatch<React.SetStateAction<boolean>>;
}

function Container({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={`demo-container rounded-lg bg-white drop-shadow-md ${className}`} {...props}>
      {children}
    </div>
  );
}

export default function Profile({ user, showDashboard, setShowDashboard }: ProfileProps) {
  const [isGitHubAuth, setIsGitHubAuth] = useState<string | null>(null);
  const [isDiscordAuth, setIsDiscordAuth] = useState<string | null>(null);
  const [isLinkedInAuth, setIsLinkedInAuth] = useState<string | null>(null);
  const [isMetaMaskAuth, setIsMetaMaskAuth] = useState<string | null>(null);
  const [userData, setUserData] = useState<null | User>(null);

  const formatEthereumAddress = (address: string): string => {
    if (address.length < 10) return address;
    const upperAddress = address.toUpperCase()
    return `${upperAddress.slice(0, 11)}...${upperAddress.slice(-9)}`;
  };

  // const notify = () => toast('Here is your toast.');

  useEffect(() => {
    if (!user || !user.id) return;
    const fetchUserData = async () => {
      let retry = true;
      let attempts = 0;
      const maxAttempts = 5;
      while (retry && attempts < maxAttempts) {
        attempts++;
        try {
          const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/profile/${user.id}`);
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          if (data && data.id) {
            setUserData(data);
            setIsGitHubAuth(data.customFields.github || false);
            setIsDiscordAuth(data.customFields.discord || false);
            // setIsLinkedInAuth(data.customFields.linkedin && data.customFields.linkedin.access_token);
            setIsLinkedInAuth(data.customFields.linkedin || false);
            setIsMetaMaskAuth(data.customFields.metamask || false);
            retry = false; // Stop retrying once customFields is retrieved
          } else {
            throw new Error('customFields not available yet');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before retrying
        }
      }
    };

    fetchUserData();
  }, [user])

  const handleGitHubAuth = () => {
    if (!user) return;
    const authWindow = window.open(
      `${import.meta.env.VITE_SERVER_URL}/auth/github/login/${user.id}`,
      'authWindow',
      'width=500,height=600'
    );

    const interval = setInterval(() => {
      if (authWindow?.closed) {
        clearInterval(interval);
        window.location.reload(); // Refresh the profile page
      }
    }, 1000);
  };

  const handleDiscordAuth = () => {
    if (!user) return;
    const authWindow = window.open(
      `${import.meta.env.VITE_SERVER_URL}/auth/discord/login/${user.id}`,
      'authWindow',
      'width=500,height=600'
    );

    const interval = setInterval(() => {
      if (authWindow?.closed) {
        clearInterval(interval);
        window.location.reload(); // Refresh the profile page
      }
    }, 1000);
  };

  const handleLinkedinAuth = () => {
    if (!user) return;
    const authWindow = window.open(
      `${import.meta.env.VITE_SERVER_URL}/auth/linkedin/login/${user.id}`,
      'authWindow',
      'width=500,height=600'
    );

    const interval = setInterval(() => {
      if (authWindow?.closed) {
        clearInterval(interval);
        window.location.reload(); // Refresh the profile page
      }
    }, 1000);
  };

  const handleMetaMaskAuth = () => {
    if (!user) return;
    const authWindow = window.open(
      `${window.location.origin}/metamask-popup?userId=${user.id}`,
      "MetaMaskAuth",
      "width=500,height=600"
    );
  
    if (!authWindow) {
      toast.error('Popup blocked or failed to open');
      return;
    }
  
    const interval = setInterval(() => {
      if (authWindow?.closed) {
        clearInterval(interval);
        window.location.reload(); // Refresh the profile page
      }
    }, 1000);
  };

  const dashboardView = (
    <div className="iframe-dashboard flex justify-center items-center h-full">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 p-6 sm:p-8 items-start mx-auto max-w-6xl">
        
        <Container className="small">
        <ShineBorder className="" color={["#25D979", "#27C3AE", "#2AAEDD"]}>  
          <CardHeader className="flex flex-col items-center pb-4">
           <CardTitle className="">{userData?.first_name}</CardTitle>
           <CardDescription className="text-sm">{userData?.email}</CardDescription>
           </CardHeader>
          <CardContent className="space-y-3 px-4">
         
          <div className="flex justify-center space-x-6">
           <div className="relative group">
             <FaGithub
              style={{ fontSize: '1.3rem', color: userData?.customFields.github?.username ? '' : '#d1d5db' }} // dark text or light gray if missing
              className={userData?.customFields.github?.username ? 'cursor-pointer' : ''}
             />
             {userData?.customFields.github?.username && (
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-700 text-white text-xs rounded py-1 px-2">
              {userData.customFields.github.username}
               </span>
             )}
           </div>

            <div className="relative group">
            <FaDiscord
              style={{ fontSize: '1.3rem', color: userData?.customFields.discord?.username ? '' : '#d1d5db' }} // discord color or light gray if missing
              className={userData?.customFields.discord?.username ? 'cursor-pointer' : ''}
            />
            {userData?.customFields.discord?.username && (
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-700 text-white text-xs rounded py-1 px-2">
                {userData.customFields.discord.username}
              </span>
            )}
            </div>

            <div className="relative group">
            <FaLinkedin
              style={{ fontSize: '1.3rem', color: userData?.customFields.linkedin?.name ? '' : '#d1d5db' }} // linkedin color or light gray if missing
              className={userData?.customFields.linkedin?.name ? 'cursor-pointer' : ''}
            />
            {userData?.customFields.linkedin?.name && (
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-700 text-white text-xs rounded py-1 px-2">
                {`${userData.customFields.linkedin.name} - ${userData.customFields.linkedin.locale.country}`}
              </span>
            )}
            </div>

            <div className="relative group">
            <FaEthereum
              style={{ fontSize: '1.3rem', color: userData?.customFields.metamask ? '' : '#d1d5db' }} // ethereum color or light gray if missing
              className={userData?.customFields.metamask ? 'cursor-pointer' : ''}
            />
            {userData?.customFields.metamask && (
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 opacity-0 group-hover:opacity-100 transition-opacity bg-gray-700 text-white text-xs rounded py-1 px-2">
                {formatEthereumAddress(userData.customFields.metamask)}
              </span>
            )}
            </div>
          </div>
                 
         <div className="border-t mx-4"></div> {/* Separator */}
         </CardContent>
         <CardFooter className="flex justify-center space-x-3">
            <Switch
              id="dashboard-mode"
              checked={showDashboard}
              onCheckedChange={setShowDashboard}
              className="transition-colors duration-300"
              />
            <Label
              htmlFor="dashboard-mode"
              className={`text-sm font-medium transition-colors duration-300 ${showDashboard ? 'text-[#27C3AE]' : 'text-gray-400'}`}
              >
              Dashboard
            </Label> 
         </CardFooter>
 
        </ShineBorder>
        </Container>

        <Container className="">
          <Github user={userData} />        
        </Container>    
        <Container className="col-span-2 md:col-span-2 md:row-span-2">
          <Linkedin user={userData} />        
        </Container>
         {/* Large Container (takes 12 columns or 4 grid spaces on md+) */}
      {/* <Container className="col-span-12 md:col-span-12 xl:col-span-8">
        <Linkedin user={userData} />        
      </Container> */}
      </div>
    </div>
  )

  const regularView = (
    <div className="iframe-container flex justify-center items-center h-full">
      <div className="p-4 rounded-lg bg-white">
      <Tilt className="Tilt" options={{ max: 10, perspective: 1400, easing: "cubic-bezier(.03,.98,.52,.99)", transition: true, reset: false, speed: 800, glare: true, maxGlare: 0.2, scale: 1.0 }}>
      <ShineBorder className="p-4 rounded-lg bg-white mx-auto max-w-sm drop-shadow-md" color={["#25D979", "#27C3AE", "#2AAEDD"]}>
          <CardHeader className="flex flex-col items-center pb-2">
            <Avatar className="w-16 h-16 mb-2">
              <AvatarImage src="https://i.ibb.co/whTPP0N/test.jpg" alt="@shadcn" />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <CardTitle className="text-lg">{userData?.first_name ?? 'Pseudo User'}</CardTitle>
            <CardDescription className="text-sm">{userData?.email ?? 'pseudo@email.com'}</CardDescription>
          </CardHeader>
          <CardContent className="space-y-4 px-4">
            <div className="border-t my-2 mx-4"></div> {/* Separator */}
            <div className="flex items-center gap-2">
              <FaGithub style={{ fontSize: '1.3rem' }} />
              {isGitHubAuth ? (
                <Input className="text-sm flex-grow" value={userData?.customFields.github?.username || ''} readOnly placeholder="GitHub Username" />
              ) : (
                <Button size="sm" className="w-full bg-teal-500" onClick={handleGitHubAuth}>
                  Link GitHub
                </Button>
              )}
            </div>
            <div className="border-t border-dashed my-2 mx-4"></div> {/* Separator */}
            <div className="flex items-center gap-2">
              <FaDiscord style={{ fontSize: '1.3rem' }} />
              {isDiscordAuth ? (
                <Input className="text-sm flex-grow" value={userData?.customFields.discord?.username || ''} readOnly placeholder="Discord Username" />
              ) : (
                <Button size="sm" className="w-full bg-teal-500" onClick={handleDiscordAuth}>
                  Link Discord
                </Button>
              )}
            </div>
            <div className="border-t border-dashed my-2 mx-4"></div> {/* Separator */}
            <div className="flex items-center gap-2">
              <FaLinkedin style={{ fontSize: '1.3rem' }} />
              {isLinkedInAuth ? (
                <Input className="text-sm flex-grow" value={userData?.customFields.linkedin?.name + ' - ' + userData?.customFields.linkedin?.locale.country || ''} readOnly placeholder="LinkedIn Username" />
              ) : (
                <Button size="sm" className="w-full bg-teal-500" onClick={handleLinkedinAuth}>
                  Link LinkedIn
                </Button>
              )}
            </div>
            <div className="border-t border-dashed my-2 mx-4"></div> {/* Separator */}
            <div className="flex items-center gap-2">
              <FaEthereum style={{ fontSize: '1.3rem' }} />
              {isMetaMaskAuth ? (
                <Input className="text-sm flex-grow" value={formatEthereumAddress(userData?.customFields.metamask || '')} readOnly placeholder="MetaMask Address" />
              ) : (
                <Button size="sm" className="w-full bg-teal-500" onClick={handleMetaMaskAuth}>
                  Link MetaMask
                </Button>
              )}
            </div>
          </CardContent>

          <CardFooter className="hidden md:flex justify-center space-x-3">
            <Switch
              id="dashboard-mode"
              checked={showDashboard}
              onCheckedChange={setShowDashboard}
              className="transition-colors duration-300"
            />
            <Label
              htmlFor="dashboard-mode"
              className={`text-sm font-medium transition-colors duration-300 ${showDashboard ? 'text-[#27C3AE]' : 'text-gray-400'}`}
            >
              Dashboard
            </Label>
          </CardFooter>

          
      </ShineBorder>
      </Tilt>
      </div>
    </div>
  );

  return (
    <div>
    { showDashboard ? dashboardView : regularView}
    </div>
  )
  
}
