// import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { User } from './types/user';


window.reactRoot = null;

const devUser: User = {
  id: Number(import.meta.env.VITE_DEV_USER_ID) || 0,
  username: import.meta.env.VITE_DEV_USER_USERNAME || 'pseudo_user',
  email: import.meta.env.VITE_DEV_USER_EMAIL || 'pseudo_user@example.com',
  customFields: {
    github: undefined,
    discord: undefined
  }
};

const allowedOrigin = import.meta.env.VITE_ALLOWED_ORIGIN;
const devOrigins = [
  import.meta.env.VITE_SERVER_URL,
  import.meta.env.VITE_ALLOWED_NGROK,
  import.meta.env.VITE_ALLOWED_ORIGIN,
  'http://localhost:4173',
  'http://localhost:5173'
];

window.initializeApp = (userData: User | null = null) => {
  const container = document.getElementById('react-app');
  if (container) {
    if (!window.reactRoot) {
      window.reactRoot = createRoot(container);
    }
    // if (!userData) {
    //   console.warn('User data is null, app will not be initialized.');
    //   return;
    // }
    window.reactRoot.render(
      <App user={userData} />
      // <React.StrictMode>
      //   <App user={userData} />
      // </React.StrictMode>
    );
  }
};

const receiveMessage = (event: MessageEvent) => {
  const origin = event.origin.toLowerCase();
  if (import.meta.env.VITE_MODE === 'development') {
    if (!devOrigins.includes(origin)) {
      console.warn("Untrusted dev origin:", origin);
      return;
    }
  } else {
    if (origin !== allowedOrigin.toLowerCase()) {
      //console.warn("Untrusted prod origin:", origin);
      return;
    }
  }

  // console.log("This is the origin: ", event.origin);
  // console.log("This is the event: ", event);

  const userData = event.data;
  if (userData && userData.id && userData.username && userData.email) {
    // console.log("Received userData:", userData);
    if (window.initializeApp) {
      window.initializeApp(userData);
    } else {
      console.error("initializeApp function is not defined.");
    }
  }
};

window.addEventListener("message", receiveMessage, false);

if (window.wpUser) {
  window.initializeApp(window.wpUser);
} if (import.meta.env.VITE_MODE === 'development') {
  window.initializeApp(devUser);
} else {
  window.initializeApp(null);
}