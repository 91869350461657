import { useState, useEffect} from "react"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { CheckCircle2, XCircle, GitBranch, Users, ArrowUpRight } from "lucide-react"
import { User } from '../types/user';
import { FaGithub } from 'react-icons/fa';
import toast from 'react-hot-toast';

interface GithubProps {
    user: User | null;
}

export default function Github({ user }: GithubProps) {
  const [isInOrg, setIsInOrg] = useState<boolean | undefined>(false);
  const [hasRepo, setHasRepo] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState(true);
  const [cacheRepo, setCacheRepo] = useState<undefined | string>(undefined);

  const githubUser = user?.customFields.github;
  const studentRepo = githubUser?.orgRepo || cacheRepo; 

  useEffect(() => {
    if (githubUser && githubUser.username && user.id) {
      const checkMembership = async () => {
        try {
          const url = `${import.meta.env.VITE_SERVER_URL}/api/github/verify-member/${githubUser.username}/${user.id}`;

          const response = await fetch(url);
          const data = await response.json();

          if (data.success) {
            setIsInOrg(true);
          } else {
            setIsInOrg(false);
          }
        } catch (error) {
          console.error("Error checking organization membership:", error);
        }
      };

      const checkRepo = async () => {
        try {
          const url = `${import.meta.env.VITE_SERVER_URL}/api/github/verify-repo/${githubUser.username}/${user.id}`;
          const response = await fetch(url);
          const data = await response.json();

          if (data.success) {
            setHasRepo(true);
          } else {
            setHasRepo(false);
          }
        } catch (error) {
          console.error("Error checking organization repository:", error);
        }
      };

      const needsVerification = !githubUser.githubOrg || !githubUser.lastVerified ||
        new Date().getTime() - new Date(githubUser.lastVerified).getTime() > 24 * 60 * 60 * 1000;

      const needsRepoCheck = !githubUser.orgRepo;

      const runChecks = async () => {
        if (needsVerification) {
          await checkMembership();
        } else {
          setIsInOrg(githubUser.githubOrg);
        }

        if (needsRepoCheck) {
          await checkRepo();
        } else {
          setHasRepo(!!githubUser.orgRepo);
        }

        setLoading(false);
      };

      runChecks();
    } else {
      console.log("Missing username or already in org:", githubUser);
      setLoading(false);
    }
  }, [githubUser, user]);

  const handleRequestAccess = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_SERVER_URL}/api/github/invite-member`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: githubUser?.email }),
        }
      );

      if (!response) {
        throw new Error("Failed to invite user");
      }

      // const data = await response.json();
      toast.success('You have been invited to the organization successfully!');
      setTimeout(() => {
        toast(`Please check your email associated with GitHub to accept the invitation:\n- ${githubUser?.email}\n\nOnce you have accepted, return here and refresh the page.`,
        {
            duration: 6000,
        });
      }, 1000);
    } catch (error) {
      toast.error('Failed to invite you to the organization.');
      console.error("Error inviting user:", error);
    }
  };

  const handleCreateRepo = () => {
    const repoCreationPromise = new Promise((resolve, reject) => {
        if (!user) return reject('Forbidden, no user')
        fetch(`${import.meta.env.VITE_SERVER_URL}/api/github/create-repo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            handle: githubUser?.username,
            studentId: user.id
          }),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Failed to create repository");
            }
            return response.json();
          })
          .then(data => {
            if (data.success) {
              setHasRepo(true);
              const repoLink = `https://github.com/codecrypto-academy/${githubUser?.username}`;
              setCacheRepo(repoLink);
              resolve('Repository created successfully!');
            } else {
              reject('Failed to create the repository.');
            }
          })
          .catch(error => {
            console.error("Error creating repository:", error);
            reject('An error occurred while creating the repository.');
          });
    });
  
    toast.promise(repoCreationPromise, {
      loading: 'Creating repository...',
      success: (msg) => `${msg}`,
      error: (err) => `${err}`,
    });
  };

  // p-4 rounded-lg bg-white mx-auto max-w-sm drop-shadow-md border border-black

  if (!user?.customFields?.github) {
    return (
       <Card className="flex items-center justify-center h-full p-14 bg-gradient-to-r from-gray-100 to-gray-200">
        <div className="text-center space-y-4">
          <FaGithub className="w-16 h-16 mx-auto text-gray-400 transition duration-300 hover:text-gray-600" /> {/* GitHub logo */}
          <p className="text-sm text-gray-500 transition duration-300 hover:text-gray-700">Connect your Github account</p>
        </div>
      </Card>      
    );
  }
  

  return (
    <Card className="">
      <CardHeader className="flex flex-row items-center space-x-4 pb-3">
        <div>
          <CardTitle>Github</CardTitle>
          <p className="text-sm text-muted-foreground">
            @{githubUser?.username}
          </p>
        </div>
      </CardHeader>
      <CardContent className="">
        <div className="space-y-2">
          <div className="flex items-center justify-between h-full">
            <div className="flex items-center space-x-2">
              <Users className="w-4 h-4" />
              <span className="text-sm font-medium">Organization Member</span>
            </div>
            {isInOrg || githubUser?.githubOrg ? (
              <CheckCircle2 className="w-5 h-5 text-green-500" />
            ) : (
              <XCircle className="w-5 h-5 text-red-500" />
            )}
          </div>
          <div className="flex items-center justify-between h-full">
            <div className="flex items-center space-x-2">
              <GitBranch className="w-4 h-4" />
              <span className="text-sm font-medium">Repository</span>
            </div>
            {hasRepo || githubUser?.orgRepo ? (
              <CheckCircle2 className="w-5 h-5 text-green-500" />
            ) : (
              <XCircle className="w-5 h-5 text-red-500" />
            )}
          </div>
        </div>
      </CardContent>
      <CardFooter className="space-y-2">
        {loading && !githubUser?.githubOrg && !studentRepo && (
          <Button disabled className="w-full max-w-xs">
            Loading...
          </Button>
        )}
        {!loading && !githubUser?.githubOrg && (
          <Button className="w-full max-w-xs" onClick={handleRequestAccess}>
            Request Organization Access
          </Button>
        )}
        {!loading && githubUser?.githubOrg && !githubUser?.orgRepo && !cacheRepo && (
          <Button className="w-full max-w-xs" onClick={handleCreateRepo}>Create Repository</Button>
        )}
        
        {githubUser?.githubOrg && studentRepo && (
        <Button 
            className="ml-auto gap-2 shadow-sm hover:shadow-md transition-shadow duration-300"
            asChild>
          <a href={studentRepo} target="_blank" rel="noopener noreferrer">
            View your repository
            <ArrowUpRight className="h-4 w-4" />
          </a>
        </Button>        
        
        )}
      </CardFooter>
    </Card>
  );
}

