import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './pages/Profile';
import MetaMaskPopup from './pages/MetamaskPopup';
import Success from './pages/verification/Success';
import Error from './pages/verification/Error';
import { User } from './types/user';
import { Toaster } from 'react-hot-toast';

const currentPath = window.location.pathname;

if (import.meta.env.VITE_MODE !== 'development' && window.top === window.self && !['/success', '/error', '/metamask-popup'].includes(currentPath)) {
  window.location.href = import.meta.env.VITE_ALLOWED_ORIGIN;
}

interface AppProps {
  user: User | null; 
}

const defaultUser: User = {
  id: 999999999,
  username: '',
  email: '',
  customFields: {}
};

const App: React.FC<AppProps> = ({ user }) => {
  const [showDashboard, setShowDashboard] = useState(false);

  const currentUser = user || defaultUser;

  return (
    <Router>
      <div>
        <Toaster reverseOrder={true}/>
        <Routes>
          <Route path="/" element={<Profile user={currentUser} showDashboard={showDashboard} setShowDashboard={setShowDashboard}/>} />
          <Route path="/metamask-popup" element={<MetaMaskPopup />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;